<script setup>
import LoginForm from '@/components/LoginForm.vue'

const { t } = useI18n()
const title = useTitle()

definePage({
  meta: {
    public: true,
  },
})

title.value = t('login')

const appName = import.meta.env.VITE_APP_NAME
</script>

<template>
  <main>
    <LoginForm />
  </main>
</template>
