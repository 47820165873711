import { createApp } from 'vue'
import './assets/main.scss'
import App from './App.vue'
import { registerPlugins } from '@/core'
import 'flowbite'
import * as Sentry from '@sentry/vue'
import '../node_modules/flowbite-vue/dist/index.css'

let app = createApp(App)
registerPlugins(app)

const dsn = import.meta.env.VITE_SENTRY_DSN

if (import.meta.env.VITE_SENTRY_ENABLE === 'true' && dsn) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
    integrations: [
      Sentry.browserTracingIntegration(),

      //Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/bet247\.test/, /b247\.site/],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
}

app.mount('#app')
