<template>
  <BaseModal
    v-if="showModal && hasModal && !closeModal"
    class="web-modal"
    @close="hideModal"
  >
    <template #body>
      <div
        class="w-full mx-auto"
        v-html="modalHtml"
      />
    </template>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue'
import { useStore } from '@/stores'

const store = useStore()

const closeModal = ref(false)

const showModal = computed(() => {
  return store.showModal && window.location.pathname === '/'
})

const hasModal = computed(() => {
  return store.settings?.web_popup && store.settings?.web_popup_html
})

const modalHtml = computed(() => {
  return store.settings?.web_popup_html
})

function hideModal() {
  closeModal.value = true
  store.setModalHash()

}
</script>
