<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('refer_friend') }}
        </span>
      </div>
      <div>{{ $t('refer_friend_to_get_more_benefit') }}</div>
      <div class="w-full my-5">
        <FwbInput
          v-model="state.affiliateLink"
          size="lg"
          readonly
        >
          <template #suffix>
            <FwbButton
              class="px-2 py-2"
              @click="copyToClipboard(state.affiliateLink)"
            >
              <img
                src="/img/icon-copy.svg"
                class="mx-auto mb-[2px]"
              >
            </FwbButton>
          </template>
        </FwbInput>
      </div>
      <!-- earning -->
      <h5 class="w-full mb-2 text-xl text-blue-700 text-left">
        {{ $t('referral_earning') }}
      </h5>
      <div class="profile-card w-full">
        <div class="p-3 text-white">
          <div class="flex">
            <div class="p-2">
              <div class="text-xs">
                {{ $t('total_earning') }}
              </div>
              <h5 class="mb-2 text-xl tracking-tight text-white dark:text-gray-900">
                {{ num(store.userData.earning) }}
              </h5>
              <div class="text-xs">
                {{ $t('baht') }}
              </div>
            </div>
            <div class="text-xs p-2">
              <div class="grid grid-cols-2 mb-1">
                <div>{{ $t('refer_earning') }} </div>
                <div class="text-right font-bold">
                  {{ num(state.sum.referer) }}
                </div>
              </div>
              <div class="grid grid-cols-2 mb-1">
                <div>{{ $t('friend_lost_earning') }} </div>
                <div class="text-right font-bold">
                  {{ num(state.sum.friendLost) }}
                </div>
              </div>
              <div class="grid grid-cols-2 mb-1">
                <div>{{ $t('friend_commission_earning') }} </div>
                <div class="text-right font-bold">
                  {{ num(state.sum.friendBet) }}
                </div>
              </div>
              <div class="grid grid-cols-2 mb-1">
                <div>{{ $t('self_commission_earning') }} </div>
                <div class="text-right font-bold">
                  {{ num(state.sum.ownBet) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AffiliateNav />
      <!-- earning_history -->
      <div class="my-5 w-full">
        <h5 class="mb-2 text-xl text-blue-700">
          {{ $t('earning_history') }}
        </h5>
        <FwbTable class="table-responsive">
          <FwbTableHead>
            <FwbTableHeadCell>{{ $t('type') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('amount') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('created_at') }}</FwbTableHeadCell>
          </FwbTableHead>
          <FwbTableBody>
            <template v-if="state.items.length">
              <FwbTableRow
                v-for="item in state.items.slice(0, 5)"
                :key="item.id"
              >
                <FwbTableCell class="text-center">
                  <FwbBadge
                    :type="item.color"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ item.type }}
                  </FwbBadge>
                </FwbTableCell>
                <FwbTableCell class="text-center text-md font-bold">
                  {{ num(item.amount) }}
                </FwbTableCell>
                <FwbTableCell>
                  <div class="w-full text-center">
                    {{ $d(item.paid_date, 'long') }}
                  </div>
                </FwbTableCell>
              </FwbTableRow>
            </template>
            <template v-else-if="state.ajaxLoaded">
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('no_data_text') }}
                </td>
              </FwbTableRow>
            </template>
            <template v-else>
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('data_is_loading') }}
                </td>
              </FwbTableRow>
            </template>
          </FwbTableBody>
        </FwbTable>
      </div>
    </div>
  </main>
</template>

<script setup>
import {
  FwbInput,
  FwbButton,
  FwbTable,
  FwbTableHeadCell,
  FwbTableCell,
  FwbTableHead,
  FwbTableBody,
  FwbBadge,
  FwbTableRow,
} from 'flowbite-vue'
import { useStore } from '@/stores'
import AffiliateNav from '@/components/AffiliateNav.vue'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  name: 'affiliate',
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('refer_friend')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  affiliateLink: `https://${import.meta.env.VITE_APP_HOST}/register?ref=${store.userData.id}`,
  showDialog: false,
  showContactDialog: false,
  ajaxLoaded: false,
  isLoading: false,
  user: store.userData,
  page: 1,
  totalEarning: store.userData.earning,
  items: [],
  sum: {
    referer: 0,
    friendLost: 0,
    friendBet: 0,
    ownBet: 0,
  },
})

onMounted(() => {
  if (!store.userData) {
    router.push('/login')
  }
  getProfile()
  getData()
})

async function getData() {
  const { data, error, response } = await useApi(createUrl('api/0/affiliates', {
    query: {
      page: state.page,
    },
  })).get()

  state.ajaxLoaded = true
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.totalEarning = data.value.total
    state.items = data.value.data.data
    state.sum.referer = data.value.sum.referer
    state.sum.friendLost = data.value.sum.friendLost
    state.sum.friendBet = data.value.sum.friendBet
    state.sum.ownBet = data.value.sum.ownBet
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function getProfile() {
  const { data, error, response } = await useApi(createUrl('api/0/profile')).get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    store.updateUserData(data.value)
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function handleResponse(data, error, response) {
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    store.updateUserData(data.value)
  } else if (data.value.message) {
    await swal('Error?', data.value.message, 'error')
  }
}

function beginConnect() {
  if (!window.liff.isLoggedIn()) {
    window.liff.login({
      redirectUri: window.location.href + '#line-connect',
    })
  } else {
    connectLine()
  }
}

function connectLine() {
  window.liff.getProfile().then(async profile => {
    const { data, error, response } = await useApi(createUrl('api/0/line-connect')).post({
      token: window.liff.getAccessToken(),
      line_id: profile.userId,
      line_name: profile.displayName,
      image: profile.pictureUrl,
    })

    await handleResponse(data, error, response)
  })
}

function logout() {
  swal({
    title: t('confirm_logout'),
    text: t('you_are_willing_to_logout'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('confirm'),
    cancelButtonText: t('close'),
  }).then(async result => {
    if (result.isConfirmed) {
      store.updateUserData(null)

      await swal({
        icon: 'success',
        text: t('logout_successful'),
      })
      router.push('/login')
    }
  })
}
</script>

<style scoped>
.profile-card {
  border-radius: 10px;
  background: linear-gradient(180deg, #4087DB 0%, #3260A6 100%);
}
</style>
