import { createMongoAbility } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import { getUserData } from '@/utils'

export default function (app) {
  const existingAbility = getUserData()?.ability ?? []
  const initialAbility = createMongoAbility(existingAbility)

  app.use(abilitiesPlugin, initialAbility, {
    useGlobalProperties: true,
  })
}
