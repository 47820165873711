<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('profile') }}
        </span>
      </div>
      <!-- hello -->
      <div class="profile-card mt-4 w-full">
        <div class="p-5 card-bg">
          <h5 class="mb-2 text-xl tracking-tight text-white dark:text-gray-900">
            {{ $t('hello') }}, {{ store.userData?.name }}
          </h5>
          <div class="text-xs">
            {{ $t('are_you_ready_to_have_fun_with_us_today') }}
          </div>
        </div>
      </div>
      <!-- profile -->
      <div class="mt-5 w-full">
        <h5 class="mb-2 text-xl text-blue-700">
          {{ $t('profile') }}
        </h5>
        <FwbCard class="p-3 w-full text-sm">
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('first_name') }}
            </div>
            <div>{{ store.userData?.first_name }}</div>
          </div>
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('last_name') }}
            </div>
            <div>{{ store.userData?.last_name }}</div>
          </div>
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('mobile') }}
            </div>
            <div>{{ store.userData?.mobile }}</div>
          </div>
          <div class="flex mt-2">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('connect_line') }}<br>
              <div
                v-if="store.userData?.line_id"
                class="inline-block bg-[#47DA44] shadow rounded-[10px] px-2 py-2 align-bottom"
              >
                <img
                  src="/img/input/line.svg"
                  alt=""
                >
              </div>
            </div>
            <div>
              <div v-if="! store.userData?.line_id">
                <div
                  class="inline-flex bg-[#47DA44] shadow rounded-[10px] px-2 py-2 align-bottom text-white cursor-pointer"
                  @click="beginConnect"
                >
                  <img
                    src="/img/input/line.svg"
                    alt=""
                  >
                  <span class="ps-1">
                    {{ $t('connect_line') }}
                  </span>
                </div>
              </div>
              <div v-else>
                {{ store.userData?.line_name }}
                <FwbBadge
                  type="green"
                  class="inline-block whitespace-nowrap border py-2"
                >
                  {{ $t('line_connected') }}
                </FwbBadge>
              </div>
            </div>
          </div>
        </FwbCard>
      </div>
      <!-- bank_account -->
      <div class="my-5 w-full">
        <h5 class="mb-2 text-xl text-blue-700">
          {{ $t('bank_account') }}
        </h5>
        <FwbCard class="p-3 w-full text-sm">
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('bank_name') }}
            </div>
            <div>
              <FwbImg
                :alt="state.banks.find(o => o.id === state.user.bank_id)?.name"
                :src="state.banks.find(o => o.id === state.user.bank_id)?.logo"
                width="40"
              />
              {{ state.banks.find(o => o.id === state.user.bank_id)?.name }}
            </div>
          </div>
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('bank_account_number') }}
            </div>
            <div>{{ store.userData?.bank_account_number }}</div>
          </div>
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('bank_account_name') }}
            </div>
            <div>{{ store.userData?.bank_account_name }}</div>
          </div>
        </FwbCard>
      </div>
      <!-- password -->
      <div class="mb-5 w-full">
        <FwbCard class="p-3 w-full text-sm">
          <div class="flex">
            <div class="flex-initial w-5/12 font-bold">
              {{ $t('password') }}
            </div>
            <div>
              <div
                class="px-3 border border-blue-500 rounded-xl text-blue-700 cursor-pointer"
                @click="state.showDialog = true"
              >
                {{ $t('change_password') }}
              </div>
            </div>
          </div>
        </FwbCard>
      </div>
      <h5 class="w-full mb-2 text-xl text-blue-700 text-left">
        {{ $t('your_balance') }}
      </h5>
      <!-- your_balance -->
      <div class="profile-card w-full mb-5">
        <div class="p-5 card-bg card-bg2">
          <h5 class="mb-2 text-xl tracking-tight text-white dark:text-gray-900">
            {{ store.userData?.balance }}
          </h5>
          <div class="text-xs">
            <RouterLink
              to="/deposit"
              class="underline"
            >
              {{ $t('deposit_more') }}
            </RouterLink>
          </div>
        </div>
      </div>
      <!-- transaction -->
      <div class="mt-5 w-full">
        <h5 class="mb-2 text-xl text-blue-700">
          {{ $t('transaction-history') }}
        </h5>
        <FwbTable class="table-responsive">
          <FwbTableHead>
            <FwbTableHeadCell>{{ $t('type') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('total') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('status') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('created_at') }}</FwbTableHeadCell>
          </FwbTableHead>
          <FwbTableBody>
            <template v-if="state.items.length">
              <FwbTableRow
                v-for="item in state.items.slice(0, 5)"
                :key="item.id"
              >
                <FwbTableCell class="text-center">
                  <FwbBadge
                    :type="item.type === 0 ? 'green' : 'red'"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ item.type === 0 ? $t('deposit') : $t('withdraw') }}
                  </FwbBadge>
                </FwbTableCell>
                <FwbTableCell
                  class="text-center text-md"
                  :class="item.type === 0 ? 'text-green-500' : 'text-red-500'"
                >
                  {{ item.type === 0 ? '+' : '-' }}{{ num(item.total) }}
                </FwbTableCell>
                <FwbTableCell class="text-center">
                  <FwbBadge
                    type="green"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ $t('success') }}
                  </FwbBadge>
                </FwbTableCell>
                <FwbTableCell>
                  <div class="w-full text-center">
                    {{ $d(item.created_at, 'long') }}
                  </div>
                </FwbTableCell>
              </FwbTableRow>
            </template>
            <template v-else-if="state.ajaxLoaded">
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('no_data_text') }}
                </td>
              </FwbTableRow>
            </template>
            <template v-else>
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('data_is_loading') }}
                </td>
              </FwbTableRow>
            </template>
          </FwbTableBody>
        </FwbTable>
        <div class="text-center">
          <RouterLink
            class="btn-gray mt-5"
            to="/user/transaction"
          >
            {{ $t('view_all_items') }}
          </RouterLink>
        </div>
      </div>
      <!-- affiliate -->
      <div class="mt-5 w-full">
        <h5 class="mb-2 text-xl text-blue-700">
          {{ $t('want_more_credit_try_refer_friend') }}
        </h5>
        <RouterLink
          class="btn-primary"
          to="/user/affiliate"
        >
          {{ $t('refer_friend') }}
        </RouterLink>
      </div>
      <hr class="w-full my-4">
      <!-- logout -->
      <div class="w-full my-4 text-center">
        <div>
          <div
            class="btn-primary"
            @click="logout"
          >
            {{ $t('logout') }}
          </div>
          <div class="my-3">
            {{ $t('has_problem') }}
            <span
              class="cursor-pointer underline"
              @click.prevent="state.showContactDialog = true"
            >{{ $t('contact_us') }}</span>
          </div>
        </div>
      </div>
    </div>
    <BaseModal
      v-if="state.showDialog"
      class="web-modal"
      size="sm"
      persistent
      close-button
      @close="state.showDialog = false"
    >
      <template #header>
        <h3 class="text-blue-700">
          {{ $t('change_password') }}
        </h3>
      </template>
      <template #body>
        <ChangePassword @close="state.showDialog = false" />
      </template>
    </BaseModal>
    <BaseModal
      v-if="state.showContactDialog"
      class="web-modal"
      size="sm"
      @close="state.showContactDialog = false"
    >
      <template #body>
        <div class="w-full mx-auto text-center p-6">
          <img
            :src="`https://qr-official.line.me/gs/M_${lineId}_GW.png?oat_content=qr`"
            width="200"
            class="mx-auto"
          >
          <div>
            <a :href="lineOa">
              {{ lineOa }}
            </a>
          </div>
        </div>
      </template>
    </BaseModal>
  </main>
</template>

<script setup>
import {
  FwbCard,
  FwbButton,
  FwbBadge,
  FwbImg,
  FwbPagination,
  FwbTab,
  FwbTable,
  FwbTableBody,
  FwbTableRow,
  FwbTableCell,
  FwbTableHead,
  FwbTableHeadCell,
  FwbSelect,
  FwbTabs,
  FwbA,
} from 'flowbite-vue'
import BaseModal from '@/components/BaseModal.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import { useStore } from '@/stores'
import { required, mobile, sameAs, strongPassword } from '@/utils/i18n-validators'

const emitter = inject('emitter')
const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')
const lineId = import.meta.env.VITE_LINE_ID
const lineOa = import.meta.env.VITE_LINE_OA


definePage({
  name: 'profile',
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('profile')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  activeTab: window.location.hash === '#change-password' ? t('change_password') : t('profile'),
  showDialog: false,
  showContactDialog: false,
  isLoading: false,
  user: store.userData,
  editable: false,
  banks: [],
  showCurrentPassword: false,
  showPassword: false,
  showPasswordConfirm: false,
  passwordData: {
    current_password: '',
    password: '',
    password_confirmation: '',
  },
  page: 1,
  items: [],
})

emitter.on(['change-language'], lang => {
  state.activeTab = t('profile')
})

onMounted(() => {
  if (!store.userData) {
    router.push('/login')
  }
  getProfile()
  getData()
  state.user.prefix = store.userData?.prefix
  state.user.first_name = store.userData?.first_name
  state.user.last_name = store.userData?.last_name
  state.user.mobile = store.userData?.mobile
  if (window.location.hash.match(/#line-connect/)) {
    connectLine()
  } else if (window.location.hash.match(/#change-password/)) {
    window.location.hash = '#'
    swal('Error?', t('please_change_password'), 'error').then(() => {
      state.showDialog = true
    })
  }
})

async function getData() {
  const { data, error, response } = await useApi(createUrl('api/0/transactions', {
    query: {
      page: state.page,
    },
  })).get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.items = data.value.data.data
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function getProfile() {
  const { data, error, response } = await useApi(createUrl('api/0/profile')).get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    store.updateUserData(data.value)
    state.banks = data.value.banks
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function handleResponse(data, error, response) {
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    store.updateUserData(data.value)
    await swal({ icon: 'success', text: data.value.message })
  } else if (data.value.message) {
    await swal('Error?', data.value.message, 'error')
  }
}

function beginConnect() {
  if (!window.liff.isLoggedIn()) {
    window.liff.login({
      redirectUri: window.location.href + '#line-connect',
    })
  } else {
    connectLine()
  }
}

function connectLine() {
  window.liff.getProfile().then(async profile => {
    const { data, error, response } = await useApi(createUrl('api/0/line-connect')).post({
      token: window.liff.getAccessToken(),
      line_id: profile.userId,
      line_name: profile.displayName,
      image: profile.pictureUrl,
    })

    await handleResponse(data, error, response)
  })
}

function logout() {
  swal({
    title: t('confirm_logout'),
    text: t('you_are_willing_to_logout'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('confirm'),
    cancelButtonText: t('close'),
  }).then(async result => {
    if (result.isConfirmed) {
      store.updateUserData(null)

      await swal({
        icon: 'success',
        text: t('logout_successful'),
      })
      router.push('/login')
    }
  })
}
</script>

<style scoped>
.profile-card {
  border-radius: 10px;
  background: linear-gradient(180deg, #4087DB 0%, #3260A6 100%);
}
.card-bg {
  background-image: url(/img/user-money.svg);
  color: #fff;
  background-repeat: no-repeat;
  background-position-x: right;
  min-height: 100px;
}
.card-bg2 {
  background-image: url(/img/deposit-more.svg);
}
</style>
