<script setup>
definePage({
  meta: {
    layout: 'blank',
    public: true,
  },
})
</script>

<template>
  <main class="flex items-center justify-center text-center h-full">
    <div>
      <div class="text-xl">
        You are not authorized.
      </div>
      <RouterLink
        class="mt-8"
        to="/"
      >
        Back Home
      </RouterLink>
    </div>
  </main>
</template>

