<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('promotion') }}
        </span>
      </div>
      <div class="w-full mt-4">
        <div class="grid grid-cols-2 sm:grid-cols-2 gap-3">
          <div
            v-for="promotion in state.promotions"
            :key="promotion.id"
          >
            <FwbCard
              :img-alt="promotion.promotion_name"
              :img-src="promotion.image_desktop"
              variant="image"
              @click="showPromotion(promotion)"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseModal
      v-if="state.showDialog && state.current"
      class="web-modal"
      @close="state.showDialog = false"
    >
      <template #body>
        <div class="w-full mx-auto">
          <FwbImg
            alt="Slip"
            class="w-full"
            :src="state.current.image_desktop"
          />
          <div class="p-5">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              {{ state.current.promotion_name }}
            </h5>
            <p class="font-normal text-gray-700 dark:text-gray-400">
              {{ state.current.promotion_description }}
            </p>
            <div class="my-2">
              <FwbButton
                v-if="state.current?.id !== store.userData?.promotion_id"
                type="button"
                class="btn my-4 w-auto"
                @click="selectPromotion(state.current)"
              >
                {{ $t('select_this_promotion') }}
              </FwbButton>
              <div v-else>
                <FwbAlert
                  v-if="store.userData.promotion_id"
                  icon
                  border
                  type="success"
                >
                  {{ $t('selected_this_promotion') }}
                </FwbAlert>
                <FwbButton
                  type="button"
                  class="btn my-4 w-auto"
                  @click="unSelectPromotion"
                >
                  {{ $t('dont_get_promotion') }}
                </FwbButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </main>
</template>

<script setup>
import {
  FwbImg,
  FwbButton,
  FwbCard, FwbAlert,
} from 'flowbite-vue'
import { useStore } from '@/stores'
import BaseModal from '@/components/BaseModal.vue'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('promotion')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  showDialog: false,
  isLoading: false,
  current: null,
  ajaxLoaded: false,
  user: store.userData,
  configs: {},
  promotion: null,
  promotions: [],
  banks: [],
  adminBanks: [],
})

onMounted(() => {
  getData()
})

function showPromotion(promotion) {
  state.current = promotion
  state.showDialog = true
}

async function getData() {
  const { data, error, response } = await useApi('api/0/deposit').get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.ajaxLoaded = true
    state.configs = data.value.configs
    state.promotions = data.value.promotions
    state.banks = data.value.banks
    state.adminBanks = data.value.adminBanks
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function unSelectPromotion() {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/unselect-promotion').post()

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value?.success) {
    store.updateUserData(data.value)
    state.current = null
    await swal('Success?', data.value.message, 'success')
  } else if (data.value?.message) {
    await swal('Error?', data.value.message, 'error')
  }
}

async function selectPromotion(promotion) {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/select-promotion').post({
    promotion_id: promotion.id,
  })

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value?.success) {
    store.updateUserData(data.value)
    state.current = null
    await swal('Success?', data.value.message, 'success')
    router.push('/deposit')
  } else if (data.value?.message) {
    await swal('Error?', data.value.message, 'error')
  }
}
</script>

<style>
.svg-img svg {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
