<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('withdraw') }}
        </span>
      </div>
      <div class="w-full tabs rounded-lg bg-gray-100 border shadow-lg mt-4">
        <div class="w-full rounded-lg mx-auto md:mt-0 sm:max-w-xl xl:p-0 bg-gray-100 border shadow-lg">
          <div
            v-if="state.configs.deposit_enable"
            class="p-4 space-y-4"
          >
            <div class="space-y-4 md:space-y-6">
              <div class="mb-5">
                <div class="text-body-2">
                  {{ $t('your_bank') }}
                </div>
                <div class="flex gap-2">
                  <div class="flex align-middle items-center">
                    <FwbAvatar :img="store.userData.bank_logo" />
                  </div>
                  <div class="flex-1">
                    {{ store.userData.bank_name }}<br>
                    {{ store.userData.bank_account_number }}<br>
                    {{ store.userData.bank_account_name }}
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <form
                  class="space-y-4 md:space-y-6"
                  @submit.prevent="withdrawSubmit"
                >
                  <div class="my-5">
                    <FwbInput
                      v-model="state.withdraw.amount"
                      :placeholder="$t('withdraw_amount')"
                      :label="`${$t('withdraw_amount')} (${$t('your_balance')} ${num(store.userData.balance)})`"
                      class="text-center"
                      :class="{'is-invalid': v$.amount.$error, 'is-valid': v$.amount.$dirty && !v$.amount.$error}"
                      @keydown="checkDigit"
                    >
                      <template #helper>
                        ({{ $t('withdraw_min') }} {{ num(state.configs.withdraw_min) }}
                        {{ $t('withdraw_max') }} {{ num(state.configs.withdraw_max) }})
                      </template>
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.amount.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                  <div class="mb-5">
                    <div class="grid gap-4 grid-cols-4">
                      <div
                        v-for="amount in state.amounts"
                        :key="amount"
                        class="text-center"
                      >
                        <FwbButton
                          type="button"
                          gradient="green-blue"
                          class="ms-3"
                          @click="setAmount(amount)"
                        >
                          {{ num(amount) }}
                        </FwbButton>
                      </div>
                    </div>
                  </div>
                  <div class="my-5 text-center">
                    <FwbButton>
                      {{ $t('withdraw_request') }}
                    </FwbButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mx-auto sm:max-w-xl text-center"
          >
            <FwbAlert
              icon
              border
              type="danger"
            >
              {{ $t('withdraw_diabled') }}
            </FwbAlert>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {
  FwbButton,
  FwbAlert,
  FwbTab,
  FwbTable,
  FwbTableBody,
  FwbTableCell,
  FwbTableHead,
  FwbTableHeadCell,
  FwbImg,
  FwbTabs,
  FwbInput,
  FwbAvatar,
} from 'flowbite-vue'
import { useStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import { required, mobile } from '@/utils/i18n-validators'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('withdraw')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  activeTab: t('withdraw_to_bank'),
  showDialog: false,
  isLoading: false,
  current: null,
  ajaxLoaded: false,
  user: store.userData,
  to: {
    id: null,
  },
  withdraw: {
    amount: 100,
    to_bank_id: null,
    to_bank_name: null,
    to_bank_account_number: null,
    to_bank_account_name: null,
  },
  amounts: [100, 200, 300, 400, 500, 1000, 5000, 10000],
  editable: false,
  mobile: null,
  qrcode: null,
  configs: {},
  promotion: null,
  promotions: [],
  banks: [],
  adminBanks: [],
})

const rules = {
  amount: { required },
}

const v$ = useVuelidate(rules, state.withdraw, { $lazy: true, $autoDirty: true })

onMounted(() => {
  getData()
})

function setAmount(amount) {
  if (amount < state.configs.withdraw_min) {
    state.withdraw.amount = state.configs.withdraw_min
  } else if (amount > state.configs.withdraw_max) {
    state.withdraw.amount = state.configs.withdraw_max
  } else {
    state.withdraw.amount = amount
  }
  if (state.withdraw.amount > state.user.balance) {
    state.withdraw.amount = state.user.balance
  }
}

async function getData() {
  const { data, error, response } = await useApi('api/0/withdraw').get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.configs = data.value.configs
    state.ajaxLoaded = true
    state.withdraw.amount = store.userData.balance < state.withdraw.amount ? store.userData.balance : state.withdraw.amount
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function withdrawSubmit() {
  if (state.withdraw.amount < 1) {
    swal('Error?', t('withdraw_amount_required'), 'error')

    return
  }
  const result = await v$.value.$validate()
  if(result) {
    const { data, error, response } = await useApi('api/0/withdraw').post({
      amount: state.withdraw.amount,
    })

    if (error.value) {
      const json = await response.value.json()

      swal('Error?', json.message, 'error')
    } else if (data.value.success) {
      store.updateUserData(data.value)
      swal('Success?', data.value.message, 'success')
    } else if (data.value.message) {
      await swal('Error?', data.value.message, 'error')
    }
  }
}
</script>

<style>
.svg-img svg {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
