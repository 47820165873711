<script setup>
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useStore } from '@/stores'
import Tracker from '@openreplay/tracker'

const emitter = inject('emitter')
const store = useStore()
const Swal = inject('$swal')

let tracker

if (import.meta.env.VITE_OPENREPLAY_ENABLE === 'true') {
  tracker = new Tracker({
    projectKey: import.meta.env.VITE_OPENREPLAY_KEY,
    ingestPoint: import.meta.env.VITE_OPENREPLAY_URL,
    network: {
      capturePayload: true,
    },
  })

  if (store.userData) {
    tracker.setUserID(store.userData.username)
  }
  tracker.start()
}

let msgServer

onMounted(() => {
  if (store.isUserLoggedIn) {
    getMercureToken()
    store.getUserData(0)
  }
  store.getSettings()
})
emitter.on(['logged-in'], e => {
  getMercureToken()
  if (tracker) {
    tracker.setUserID(store.userData.username)
  }
})

async function getMercureToken() {
  const { data } = await useApi('api/0/token')
  if (data.value?.token) {
    store.updateMercureToken(data.value.token)
    subscribeEvent()
  }
}

function subscribeEvent() {
  const topics = [
    'ping',
    'balance-' + store.userData.id,
    'web-deployed',
    'user-update-' + store.userData.id,
    'promotion-cleared-' + store.userData.id,
    'transaction-updated-' + store.userData.id,
  ]

  const query = topics.join('&topic=')

  msgServer = new EventSourcePolyfill(`${import.meta.env.VITE_MERCURE_URL}?topic=${query}`, {
    headers: {
      Authorization: `Bearer ${store.mercureToken}`,
    },
    heartbeatTimeout: 1805000,
  })
  msgServer.addEventListener('message', message => {
    try {
      const { data } = message
      if (data) {
        const json = JSON.parse(data)

        console.log('json', json.event, json.data)
        if (json.event.match(/web-deployed/)) {
          window.location.reload()
        } else if (json.event === 'transaction-updated' && json.data.id === store.userData.id) {
          Swal.fire({ icon: 'success', text: json.data.message })
          emitter.emit(json.event, json.data)
        } else if (json.event === 'promotion-cleared' && json.data.id === store.userData.id && window.location.pathname.match(/\/deposit/)) {
          window.location.reload()
        } else if (json.event === 'user-update' && json.data.id === store.userData.id && `${json.data.hash}` !== `${store.userHash}`) {
          window.location.reload()
        } else if (json.event === 'balance-update' && json.data.id === store.userData.id) {
          store.getUserData(0)
          emitter.emit(json.event, json.data)
        } else {
          emitter.emit(json.event, json.data)
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Error:', ex)
    }
  })
}
</script>

<template>
  <div />
</template>
