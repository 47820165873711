import { cookieRef } from '@/utils/helpers'
import { useCookie } from '@/composables/useCookie'

const keyToken = 'uToken'
const keyUser = 'uData'
const keyMercure = 'mToken'
const keyLang = 'mLang'
const keySetting = 'mSettings'
const keyModalHash = 'mModal'

export const isUserLoggedIn = () => !!(localStorage.getItem(keyUser) && localStorage.getItem(keyToken))

export const getUserData = () => JSON.parse(localStorage.getItem(keyUser))

export const getSettings = () => JSON.parse(localStorage.getItem(keySetting))

export const getModalHash = () => cookieRef(keyModalHash, null)

export const getAccessToken = () => localStorage.getItem(keyToken) ?? ''

export const getMercureToken = () => localStorage.getItem(keyMercure) ?? ''

export const getLanguage = () => localStorage.getItem(keyLang) ?? 'th'

export const getReferer = () => cookieRef('referer', null)

export const setUserData = data => {
  localStorage.setItem(keyUser, data)
}

export const setSettings = data => {
  localStorage.setItem(keySetting, data)
}

export const setModalHash = data => {
  useCookie(keyModalHash, {
    maxAge: 24 * 60 * 60,
  }).value = data
}

export const setAccessToken = data => {
  localStorage.setItem(keyToken, data)
}

export const setLanguage = data => {
  localStorage.setItem(keyLang, data)
}

export const clearAllToken = () => {
  localStorage.removeItem(keyToken)
  localStorage.removeItem(keyUser)
  localStorage.removeItem(keyMercure)
}

export const setMercureToken = data => {
  localStorage.setItem(keyMercure, data)
}
