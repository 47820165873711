import { canNavigate } from '@/core/casl'
import { isUserLoggedIn, getUserData } from '@/utils'

export const setupGuards = router => {
  // 👉 router.beforeEach
  // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  router.beforeEach(to => {
    if (to.meta.public) {
      return
    }

    const isLoggedIn = isUserLoggedIn()

    // check password reset
    const userData = getUserData()
    if (isLoggedIn && userData.password_reset && to.path !== '/user/profile') {
      return '/user/profile#change-password'
    }

    /*
          If user is logged in and is trying to access login like page, redirect to home
          else allow visiting the page
          (WARN: Don't allow executing further by return statement because next code will check for permissions)
         */
    if (to.meta.unauthenticatedOnly) {
      if (isLoggedIn)
        return '/'
      else
        return undefined
    }
    if (!canNavigate(to)) {
      /* eslint-disable indent */
      return isLoggedIn
        ? { name: 'not-authorized' }
        : {
          name: 'login',
          query: {
            ...to.query,
            to: to.fullPath !== '/' ? to.path : undefined,
          },
        }
    }
    document.title = to.meta?.title ?? import.meta.env.VITE_APP_NAME
  })
}
