import * as validators from '@vuelidate/validators'
import { getI18n } from '@/plugins/i18n'

const t = getI18n().global.t

const { createI18nMessage } = validators

const withI18nMessage = createI18nMessage({ t: t.bind(getI18n()) })

export const required = withI18nMessage(validators.required)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const between = withI18nMessage(validators.between, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
export const alpha = withI18nMessage(validators.alpha)
export const alphaNum = withI18nMessage(validators.alphaNum)
export const decimal = withI18nMessage(validators.decimal)
export const email = withI18nMessage(validators.email)
export const integer = withI18nMessage(validators.integer)
export const numeric = withI18nMessage(validators.numeric)
export const url = withI18nMessage(validators.url)

const mobileRegex = validators.helpers.regex(/^0[1-9][0-9]{8}$/)
export const mobile = validators.helpers.withMessage(t('validations.mobile'), mobileRegex)

const zipRegex = validators.helpers.regex(/^[0-9]{5}$/)
export const zipcode = validators.helpers.withMessage(t('validations.mobile'), zipRegex)

// const passwordStrength = validators.helpers.regex(/^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[!@#$%^:;'",&*()=\-_+.^?\[\]{}<>\/])+).{6,}$/)
// const passwordStrength = validators.helpers.regex(/^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[!@#$%^:;'",&*()=\-_+.^?\[\]{}<>\/])*).{6,}$/)
const passwordStrength = validators.helpers.regex(/^(?=(.*[a-zA-Z])+)(?=(.*[0-9])+).{6,}$/)
export const strongPassword = validators.helpers.withMessage(t('validations.strongPassword'), passwordStrength)
