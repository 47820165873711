<template>
  <img
    src="/img/bg.svg"
    class="absolute z-[-5]"
  >
  <Header />
  <SideNav />
  <Event />
  <div class="main-wrap">
    <RouterView />
  </div>
  <Modal />
  <Footer />
</template>

<script setup>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Event from '@/components/Event.vue'
import Modal from '@/components/Modal.vue'
import SideNav from '@/components/SideNav.vue'
import { useCookie } from '@/composables/useCookie'
import { useStore } from '@/stores'

const store = useStore()

window.liff.init({
  liffId: import.meta.env.VITE_LIFF_ID,
  bot_prompt: 'aggressive',
})

if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search)
  const refId = urlParams.get('ref')
  if (refId) {
    useCookie('ref').value = refId
  }
  const marketing = urlParams.get('mkt_name')
  if (marketing) {
    useCookie('marketing_username').value = marketing
  }
}
</script>
