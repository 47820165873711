<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('reward') }}
        </span>
      </div>
      <div class="w-full items-center flex mt-4 shadow bg-white p-2 rounded-xl">
        <FwbImg
          v-if="store.userData?.user_level_image"
          :alt="store.userData?.user_level_name"
          :src="store.userData?.user_level_image"
          width="50"
          height="50"
        />
        <div class="flex justify-between">
          <div class="font-bold mx-2">
            {{ store.userData?.user_level_name }}
          </div>
          <div class="mx-2">
            <span class="font-bold">{{ num(store.userData?.coin) }}</span> {{ $t('coin') }}
          </div>
          <a
            class="ms-auto underline"
            href="#reward-history"
          >{{ $t('reward_history') }}</a>
        </div>
      </div>
      <div class="w-full mt-4 pb-4 border-b">
        <div class="grid grid-cols-2 sm:grid-cols-2 gap-3">
          <div
            v-for="reward in state.rewards"
            :key="reward.id"
          >
            <FwbCard
              :img-alt="reward.title"
              :img-src="reward.image"
              variant="image"
              @click="showReward(reward)"
            />
            <div class="reward-text mt-3">
              <span class="font-bold">{{ num(reward.coin) }}</span> {{ $t('coin') }}<br>
              {{ reward.title }}
            </div>
          </div>
          <div v-if="state.isLoading && state.items.length === 0">
          </div>
        </div>
      </div>
      <div
        id="reward-history"
        class="page-title mt-4"
      >
        <span class="text-center font-bold">
          {{ $t('no_data_text') }}
        </span>
      </div>
      <div class="w-full items-center px-0 pb-8 mx-auto lg:py-4 sm:max-w-[50rem]">
        <FwbTable class="table-responsive">
          <FwbTableHead>
            <FwbTableHeadCell>{{ $t('reward') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('status') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('created_at') }}</FwbTableHeadCell>
          </FwbTableHead>
          <FwbTableBody>
            <template v-if="state.history.length">
              <FwbTableRow
                v-for="item in state.history"
                :key="item.id"
              >
                <FwbTableCell>
                  {{ item.reward_name }}
                </FwbTableCell>
                <FwbTableCell class="text-center">
                  <FwbBadge
                    :type="item.status_color"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ item.status_text }}
                  </FwbBadge>
                </FwbTableCell>
                <FwbTableCell>
                  <div class="w-full text-center">
                    {{ $d(item.created_at, 'long') }}
                  </div>
                </FwbTableCell>
              </FwbTableRow>
            </template>
            <template v-else-if="state.ajaxLoaded">
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('no_data_text') }}
                </td>
              </FwbTableRow>
            </template>
            <template v-else>
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('data_is_loading') }}
                </td>
              </FwbTableRow>
            </template>
          </FwbTableBody>
        </FwbTable>
      </div>
    </div>
    <FwbModal
      v-if="state.showDialog && state.current"
      close-button
      class="web-modal"
      @close="state.showDialog = false"
    >
      <template #body>
        <div class="w-full mx-auto">
          <FwbImg
            alt="Slip"
            class="w-full"
            :src="state.current.image"
          />
          <div class="p-5">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              {{ state.current.title }} ({{ num(state.current?.coin) }} {{ $t('coin') }})
            </h5>
            <p class="font-normal text-gray-700 dark:text-gray-400">
              {{ state.current.description }}
            </p>
            <div class="my-2">
              <FwbButton
                v-if="store.userData?.coin >= state.current?.coin"
                type="button"
                class="btn my-4"
                @click="selectReward"
              >
                {{ $t('claim_this_reward') }}<br>
                (<span class="font-bold">{{ num(state.current?.coin) }}</span> {{ $t('coin') }})
              </FwbButton>
            </div>
          </div>
        </div>
      </template>
    </FwbModal>
    <FwbModal
      v-if="state.showFormDialog && state.current"
      class="web-modal"
      close-button
      @close="state.showFormDialog = false"
    >
      <template #body>
        <div class="w-full mx-auto">
          <div>
            <div class="my-2">
              <div class="page-title mb-2">
                <span>
                  {{ $t('fill_shipping_information') }}
                </span>
              </div>
              <form
                novalidate
                @submit.prevent="onSubmit"
              >
                <div class="grid gap-3 grid-cols-2">
                  <!-- first_name -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.first_name"
                      :placeholder="$t('first_name')"
                      :label="$t('first_name')"
                      maxlength="150"
                      :class="{'is-invalid': v$.first_name.$error, 'is-valid': v$.first_name.$dirty && !v$.first_name.$error}"
                      @keydown="checkNonDigitAndSpecial"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.first_name.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                  <!-- last_name -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.last_name"
                      :placeholder="$t('last_name')"
                      :label="$t('last_name')"
                      maxlength="150"
                      :class="{'is-invalid': v$.last_name.$error, 'is-valid': v$.last_name.$dirty && !v$.last_name.$error}"
                      @keydown="checkNonDigitAndSpecial"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.last_name.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                </div>
                <div class="grid gap-3 grid-cols-2">
                  <!-- mobile -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.mobile"
                      :placeholder="$t('mobile')"
                      :label="$t('mobile')"
                      maxlength="10"
                      :class="{'is-invalid': v$.mobile.$error, 'is-valid': v$.mobile.$dirty && !v$.mobile.$error}"
                      @keydown="checkDigit"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.mobile.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                  <!-- address -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.address"
                      :placeholder="$t('address')"
                      :label="$t('address')"
                      maxlength="255"
                      :class="{'is-invalid': v$.address.$error, 'is-valid': v$.address.$dirty && !v$.address.$error}"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.address.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                </div>
                <div class="grid gap-3 grid-cols-2">
                  <!-- zipcode -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.zipcode"
                      :placeholder="$t('zipcode')"
                      :label="$t('zipcode')"
                      maxlength="5"
                      :class="{'is-invalid': v$.zipcode.$error, 'is-valid': v$.zipcode.$dirty && !v$.zipcode.$error}"
                      @keydown="checkDigit"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.zipcode.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                  <!-- sub_district -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.sub_district"
                      :placeholder="$t('sub_district')"
                      :label="$t('sub_district')"
                      maxlength="255"
                      :class="{'is-invalid': v$.sub_district.$error, 'is-valid': v$.sub_district.$dirty && !v$.sub_district.$error}"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.sub_district.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                </div>
                <div class="grid gap-3 grid-cols-2">
                  <!-- district -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.district"
                      :placeholder="$t('district')"
                      :label="$t('district')"
                      maxlength="255"
                      :class="{'is-invalid': v$.district.$error, 'is-valid': v$.district.$dirty && !v$.district.$error}"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.district.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                  <!-- province -->
                  <div class="mb-3">
                    <FwbInput
                      v-model="state.province"
                      :placeholder="$t('province')"
                      :label="$t('province')"
                      maxlength="255"
                      :class="{'is-invalid': v$.province.$error, 'is-valid': v$.province.$dirty && !v$.province.$error}"
                    >
                      <template #validationMessage>
                        <div
                          v-for="(error, i) in v$.province.$errors"
                          :key="i"
                          class="text-red-500 text-xs ps-1"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </FwbInput>
                  </div>
                </div>
                <div class="my-2">
                  <FwbButton
                    type="submit"
                    class="btn my-4"
                  >
                    {{ $t('save') }}
                  </FwbButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </FwbModal>
  </main>
</template>

<script setup>
import {
  FwbImg,
  FwbButton,
  FwbCard,
  FwbModal,
  FwbInput,
  FwbTableHeadCell,
  FwbTableCell,
  FwbTableHead,
  FwbTableBody,
  FwbBadge,
  FwbTableRow,
  FwbTable,
} from 'flowbite-vue'
import { useStore } from '@/stores'
import { required, mobile, zipcode } from '@/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('reward')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  showDialog: false,
  showFormDialog: false,
  isLoading: false,
  current: null,
  ajaxLoaded: false,
  user: store.userData,
  configs: {},
  reward: null,
  userAddress: {
    address: '',
    sub_district: '',
    district: '',
    province: '',
    zipcode: '',
  },
  history: [],
  rewards: [],
  first_name: store.userData.first_name,
  last_name: store.userData.last_name,
  mobile: store.userData.mobile,
  address: '',
  sub_district: '',
  district: '',
  province: '',
  zipcode: '',
})

const rules = {
  first_name: { required },
  last_name: { required },
  mobile: { required, mobile },
  address: { required },
  sub_district: { required },
  district: { required },
  province: { required },
  zipcode: { required, zipcode },
}

const v$ = useVuelidate(rules, state, { $lazy: true, $autoDirty: true })

onMounted(() => {
  getData()
})

function showReward(reward) {
  state.current = reward
  state.showDialog = true
}

function selectReward() {
  state.showFormDialog = true
}

async function getData() {
  const { data, error, response } = await useApi('api/0/rewards').get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.ajaxLoaded = true
    state.rewards = data.value.data
    state.history = data.value.history
    state.address = data.value.userAddress?.address
    state.sub_district = data.value.userAddress?.sub_district
    state.district = data.value.userAddress?.district
    state.province = data.value.userAddress?.province
    state.zipcode = data.value.userAddress?.zipcode
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function onSubmit() {
  const result = await v$.value.$validate()
  if(result) {
    state.isLoading = true

    const { data, error, response } = await useApi(`api/0/rewards/redeem/${state.current.id}`).post({
      reward_id: state.current.id,
      first_name: state.first_name,
      last_name: state.last_name,
      mobile: state.mobile,
      address: state.address,
      sub_district: state.sub_district,
      district: state.district,
      province: state.province,
      zipcode: state.zipcode,
    })

    state.isLoading = false
    if (error.value) {
      const json = await response.value.json()

      swal('Error?', json.message, 'error')
    } else if (data.value?.success) {
      store.updateUserData(data.value)
      await swal('Success?', data.value.message, 'success')
      state.current = null
      state.showDialog = false
      state.showFormDialog = false
      getData()
    } else if (data.value?.message) {
      await swal('Error?', data.value.message, 'error')
    }
  } else {
    console.log(v$.value.$errors)
  }
}
</script>

<style>
.svg-img svg {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
