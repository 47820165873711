import { createI18n } from 'vue-i18n'
import { getLanguage } from '@/utils'

const messages = Object.fromEntries(Object.entries(import.meta.glob('./locales/*.json', { eager: true }))
  .map(([key, value]) => [key.slice(10, -5), value.default]))

let _i18n = null

const dateFormat = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
}

export const getI18n = () => {
  if (_i18n === null) {
    _i18n = createI18n({
      legacy: false,
      locale: getLanguage(),
      fallbackLocale: 'th',
      messages,
      datetimeFormats: {
        th: dateFormat,
        en: {
          short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
          long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          },
        },
        lo: dateFormat,
        my: dateFormat,
        vi: dateFormat,
      },
    })
  }

  return _i18n
}
export default function (app) {
  app.use(getI18n())
}

// jsontt th.json --module google2 --from th --to en vi my lo
