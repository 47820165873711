<template>
  <div>
    <div
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      @click="closeModal"
    />
    <div
      ref="modalRef"
      class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full justify-center items-center flex"
      tabindex="0"
      @click.self="clickOutside"
      @keyup.esc="closeWithEsc"
    >
      <div
        :class="`${modalSizeClasses[props.size]}`"
        class="relative w-full h-full flex justify-center items-center mt-5"
      >
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 w-[94%] mx-auto">
          <!-- Modal header -->
          <div
            v-if="$slots.header"
            class="border-b border-gray-200 dark:border-gray-600 p-4 rounded-t flex justify-between items-center"
          >
            <slot name="header" />
          </div>
          <!-- Modal body -->
          <div>
            <slot name="body" />
          </div>
          <!-- Modal footer -->
          <div
            v-if="$slots.footer"
            class="p-6 rounded-b border-gray-200 border-t dark:border-gray-600"
          >
            <slot name="footer" />
          </div>
          <FwbButton
            v-if="props.closeButton"
            type="button"
            size="sm"
            class="close-modal"
            @click="closeModal"
          >
            <i class="tabler-x text-xl" />
          </FwbButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FwbButton } from 'flowbite-vue'

interface ModalProps {
  notEscapable?: boolean,
  persistent?: boolean,
  size?: string,
  closeButton?: boolean,
}

const props = withDefaults(defineProps<ModalProps>(), {
  notEscapable: false,
  persistent: false,
  size: '2xl',
  closeButton: false,
})

const emit = defineEmits(['close', 'click:outside'])
const modalSizeClasses = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
}

function closeModal () {
  emit('close')
}
function clickOutside () {
  if (!props.persistent) {
    emit('click:outside')
    closeModal()
  }
}

function closeWithEsc () {
  if (!props.notEscapable && !props.persistent) closeModal()
}
const modalRef: Ref<HTMLElement | null> = ref(null)
onMounted(() => {
  if (modalRef.value) {
    modalRef.value.focus()
  }
})
</script>

<style lang="scss" scoped>
  .close-modal {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
</style>
