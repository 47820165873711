<template>
  <main>
    <div class="flex flex-col items-center px-5 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('coupon') }}
        </span>
      </div>
      <div class="w-full mt-4">
        <div class="mb-3">
          {{ $t('fill_in_coupon_to_get_credit') }}
        </div>
        <div class="grid grid-cols-2 gap-3">
          <div class="mb-3">
            <FwbInput
              v-model="state.code"
              :placeholder="$t('coupon_code')"
              maxlength="20"
            />
          </div>
          <div class="mb-3">
            <FwbButton
              class="btn"
              type="button"
              @click="submitCoupon"
            >
              {{ $t('submit') }}
            </FwbButton>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {
  FwbInput,
  FwbButton,
} from 'flowbite-vue'
import { useStore } from '@/stores'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('coupon')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  isLoading: false,
  ajaxLoaded: false,
  code: '',
})

async function submitCoupon() {
  if (! state.code) {
    swal('Error?', t('please_specify_coupon_code'), 'error')
    return
  }
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/coupons/redeem').post({
    code: state.code,
  })

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value?.success) {
    store.updateUserData(data.value)
    state.code = null
    await swal('Success?', data.value.message, 'success')
  } else if (data.value?.message) {
    await swal('Error?', data.value.message, 'error')
  }
}
</script>
