<script setup>
import { useStore } from '@/stores'

const store = useStore()
const { t } = useI18n()

const menus = [
  {
    icon: 'tabler-user',
    title: t('profile'),
    to: { name: 'profile' },
  },
  {
    icon: 'tabler-affiliate',
    title: t('refer_friend'),
    to: { name: 'affiliate' },
  },
  {
    icon: 'tabler-history',
    title: t('transaction-history'),
    to: { name: 'transaction' },
  },
  {
    icon: 'tabler-ticket',
    title: t('coupon'),
    to: { name: 'coupon' },
  },
  {
    icon: 'tabler-tag',
    title: t('promotion'),
    to: { name: 'promotion' },
  },
  {
    icon: 'tabler-gift',
    title: t('reward'),
    to: { name: 'reward' },
  },
]

const menuVisible = computed(() => store.showMenu)

function hideMenu() {
  store.setShowMenu(false)
}
</script>

<template>
  <div v-if="store.userData">
    <div
      id="cardContainer"
      class="fixed top-0 right-0 h-full w-0 overflow-hidden bg-black bg-opacity-75 backdrop-blur-lg shadow-lg transition-all duration-300 z-70"
      :class="{'w-[300px]': menuVisible, 'w-0': !menuVisible}"
    >
      <!-- Close Button -->
      <button
        id="closeButton"
        class="text-red-500 absolute top-6 right-6 z-30"
        @click="hideMenu"
      >
        <i class="tabler-x text-3xl" />
      </button>

      <!-- Card Content -->
      <div class="w-full h-full px-4 py-8 relative min-h-[450px]">
        <div class="w-full h-auto flex flex-col gap-y-1 mt-6">
          <RouterLink
            v-for="menu in menus"
            :key="menu.title"
            :to="menu.to"
            class="w-full h-auto flex items-center gap-x-4 text-gray-200 hover:text-gray-100 hover:bg-indigo-600 rounded-md px-4 py-3 ease-out duration-500 cursor-pointer"
            @click="hideMenu"
          >
            <i
              class="text-2xl"
              :class="menu.icon"
            />
            <h1 class="text-base font-medium">
              {{ menu.title }}
            </h1>
          </RouterLink>
        </div>
      </div>
    </div>
    <div
      v-if="menuVisible"
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-60"
      @click="hideMenu"
    />
  </div>
</template>
