<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('self_commission_earning') }}
        </span>
      </div>
      <div class="w-full my-5">
        <FwbCard class="p-3 w-full text-sm mx-auto">
          <div class="flex items-center">
            <div class="flex-initial w-1/2">
              {{ $t('sum_earning') }}
              <span class="font-bold">{{ num(state.totalEarning) }}</span>
              {{ $t('baht') }}
            </div>
            <div class="w-full text-end">
              <div
                class="btn-blue"
                :class="{'disabled': state.totalEarning === 0}"
                @click="getAll(3)"
              >
                {{ $t('get_all') }}
              </div>
            </div>
          </div>
        </FwbCard>
      </div>
      <!-- transaction -->
      <div class="mt-5 w-full">
        <EarningTable
          :items="state.items"
          :ajax-loaded="state.ajaxLoaded"
          @get-one="getOne"
        />
      </div>
      <AffiliateNav />
    </div>
  </main>
</template>

<script setup>
import { FwbCard } from 'flowbite-vue'
import { useStore } from '@/stores'
import AffiliateNav from '@/components/AffiliateNav.vue'
import EarningTable from "@/components/EarningTable.vue";

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  name: 'self-commission-earning',
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('self_commission_earning')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  ajaxLoaded: false,
  isLoading: false,
  page: 1,
  totalEarning: 0,
  items: [],
})

onMounted(() => {
  if (!store.userData) {
    router.push('/login')
  }
  getData()
})

async function getOne(id) {
  if (state.totalEarning > 0) {
    const { data, error, response } = await useApi('api/0/affiliates/claim').post({
      id,
    })

    await handleClaimResponse(data, error, response)
  }
}

async function getAll(type) {
  if (state.totalEarning > 0) {
    const { data, error, response } = await useApi('api/0/affiliates/claim-all').post({
      type,
    })

    await handleClaimResponse(data, error, response)
  }
}

async function getData() {
  const { data, error, response } = await useApi(createUrl('api/0/affiliates/self-commission-earning', {
    query: {
      page: state.page,
    },
  })).get()

  await handleResponse(data, error, response)
}

async function handleResponse(data, error, response) {
  state.ajaxLoaded = true
  if (error.value) {
    const json = await response.value.json()

    await swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.totalEarning = data.value.total
    state.items = data.value.data.data
  } else if(data.value.message) {
    await swal('Error?', data.value.message, 'error')
  }
}

async function handleClaimResponse(data, error, response) {
  state.ajaxLoaded = true
  if (error.value) {
    const json = await response.value.json()

    await swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    store.updateUserData(data.value)
    await swal('Success', data.value.message, 'success')
    await getData()
  } else if(data.value.message) {
    await swal('Error?', data.value.message, 'error')
  }
}
</script>

<style scoped>
.profile-card {
  border-radius: 10px;
  background: linear-gradient(180deg, #4087DB 0%, #3260A6 100%);
}
.affiliate-nav {
  @apply w-full my-5 text-left text-blue-700;
}
.affiliate-nav a {
  @apply block py-2 border-b border-gray-200;
}
</style>
