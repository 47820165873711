<script setup>
import { useStore } from '@/stores'
import BaseModal from '@/components/BaseModal.vue'

const store = useStore()
const lineId = import.meta.env.VITE_LINE_ID
const lineOa = import.meta.env.VITE_LINE_OA

const state = reactive({
  showDialog: false,
})
</script>

<template>
  <footer class="mt-auto h-[69px] bg-gradient-to-b from-[#FE59AF] to-[#F72D95] sticky bottom-0 z-50">
    <div class="grid grid-cols-6">
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <RouterLink to="/">
          <img
            src="/img/icon/home.svg"
            class="mx-auto mb-[2px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400]">{{ $t('home') }}</span>
        </RouterLink>
      </div>
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <RouterLink to="/deposit">
          <img
            src="/img/icon/deposit.svg"
            class="mx-auto mb-[2px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400] leading-[18.15px]">{{ $t('deposit') }}</span>
        </RouterLink>
      </div>
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <RouterLink to="/withdraw">
          <img
            src="/img/icon/deposit.svg"
            class="mx-auto mb-[2px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400] leading-[18.15px]">{{ $t('withdraw') }}</span>
        </RouterLink>
      </div>
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <RouterLink to="/promotion">
          <img
            src="/img/icon/promotion.svg"
            class="mx-auto mb-[-1px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400] leading-[18.15px]">{{ $t('promotion') }}</span>
        </RouterLink>
      </div>
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <a href="#">
          <img
            src="/img/icon/movie.svg"
            class="mx-auto mb-[3px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400] leading-[18.15px]">{{ $t('watch_movie') }}</span>
        </a>
      </div>
      <div class="col-span-1 mt-[10px] mx-auto justify-center items-center">
        <a
          :href="lineOa"
          @click.prevent="state.showDialog = true"
        >
          <img
            src="/img/icon/contact.svg"
            class="mx-auto mb-[3px]"
          >
          <span class="text-[#FFFFFF] text-[11px] font-[400] leading-[18.15px]">{{ $t('contact') }}</span>
        </a>
      </div>
    </div>
    <BaseModal
      v-if="state.showDialog"
      class="web-modal"
      size="sm"
      @close="state.showDialog = false"
    >
      <template #body>
        <div class="w-full mx-auto text-center p-6">
          <img
            :src="`https://qr-official.line.me/gs/M_${lineId}_GW.png?oat_content=qr`"
            width="200"
            class="mx-auto"
          >
          <div>
            <a :href="lineOa">
              {{ lineOa }}
            </a>
          </div>
        </div>
      </template>
    </BaseModal>
  </footer>
</template>
