import { defineStore } from 'pinia'
import {
  getUserData,
  isUserLoggedIn,
  getAccessToken,
  clearAllToken,
  setUserData,
  setAccessToken,
  getMercureToken,
  setMercureToken,
  getLanguage,
  getSettings,
  setSettings,
  setLanguage,
  getReferer,
  getModalHash,
  setModalHash,
} from '@/utils'
import { ability, initialAbility } from '@/plugins/casl/ability'

export const useStore = defineStore('store', {
  state: () => ({
    balance: getUserData()?.balance || 0,
    showMenu: false,
    showModal: false,
    modalHash: null,
    userHash: (new Date()).getTime(),
    settings: getSettings(),
    userData: getUserData(),
    isUserLoggedIn: isUserLoggedIn(),
    accessToken: getAccessToken(),
    mercureToken: getMercureToken(),
    referer: getReferer(),
  }),
  actions: {
    setShowMenu(val) {
      this.showMenu = val
    },
    async getUserData(refresh) {
      const { data, error, response } = await useApi(`api/0/user?refresh=${refresh}&hash=${this.userHash}`)
      if (response.value.status === 401) {
        this.logout()
        window.location.href = '/login'
      } else if (error.value) {
        const resp = await response.value.json()
      } else if (data.value.success) {
        this.updateUserData(data.value)
      }
    },
    async getSettings() {
      const { data, error, response } = await useApi('api/0/settings')
      if (data.value?.success) {
        this.settings = data.value.settings
        this.modalHash = this.settings?.web_popup && this.settings?.web_popup_hash
        this.showModal = this.settings?.web_popup && this.settings?.web_popup_html && getModalHash().value !== this.modalHash
        this.updateSettings(data.value.settings)
      }
    },
    setModalHash() {
      setModalHash(this.modalHash)
    },
    updateLanguage(val) {
      setLanguage(val)
    },
    updateSettings(val) {
      setSettings(JSON.stringify(val))
    },
    updateUserData(val) {
      if (!val) {
        clearAllToken()
        ability.update(initialAbility)
        this.isUserLoggedIn = false
        this.accessToken = ''
        this.userData = null
        this.balance = 0
      } else {
        setUserData(JSON.stringify(val.user))
        if (val.token) {
          setAccessToken(val.token)
          this.accessToken = val.token
        }
        if (val.user.ability) {
          ability.update(val.user.ability)
        }
        this.isUserLoggedIn = true
        this.userData = val.user
        this.balance = val.user.balance
      }
    },
    updateMercureToken(val) {
      setMercureToken(val)
      this.mercureToken = val
    },
    logout() {
      clearAllToken()
      ability.update(initialAbility)
      this.isUserLoggedIn = false
      this.userData = null
      this.accessToken = null
      this.mercureToken = null
    },
  },
})
