<template>
  <header class="bg-[#000000B2] border-b-[4px] border-[#F72D95] sticky top-0 z-50">
    <div class="pr-[12px] pl-[12px]">
      <div class="flex justify-between items-center">
        <img
          src="/img/logo.svg"
          class="pt-[9px] pl-[3px]"
          :alt="appName"
        >
        <div
          v-if="store.isUserLoggedIn"
          class="flex space-x-[7px] pt-[10px] justify-end"
        >
          <div class="flex items-center justify-between pl-[5px] pr-[5px] w-[110px] h-[24px] rounded-[5px] bg-gradient-to-b from-[#FE59AF] to-[#F72D95]">
            <img
              src="/img/icon_dollar.svg"
              alt="Balance"
            >
            <span class="text-[#FFFFFF] text-[15px] font-[600] pt-[3px]">
              {{ num(store.userData?.balance) }}
            </span>
          </div>
          <div
            class="cursor-pointer"
            @click="toggleMenu"
          >
            <img
              src="/img/icon_profile.svg"
              alt="Profile"
            >
          </div>
        </div>
      </div>
      <div
        v-if="store.isUserLoggedIn"
        class="grid grid-cols-2 pt-[3px]"
      >
        <div class="text-[#FFFFFF] font-[400] text-[12px] leading-[19.8px]">
          {{ $t('turnover') }} {{ num(store.userData?.current_turnover) }} / {{ num(store.userData?.turnover) }}
          ({{ turnoverProgress }}%)
        </div>
        <div class="space-x-[5px] text-right">
          <p class="text-[#FFFFFF] font-[300] text-[10px] leading-[16.5px]">
            <span>
              {{ $t('earning') }} <span class="font-[600] pl-[5px]">{{ sumEarning }}</span>
            </span>
            <RouterLink
              to="/user/affiliate"
              class="w-[30px] h-[24px] rounded-[5px] bg-gradient-to-b from-[#FE59AF] to-[#F72D95] font-[600] p-1 ms-1"
            >
              {{ $t('view') }}
            </RouterLink>
          </p>
        </div>
      </div>
      <div class="h-[10px] mb-[6px]">
        <FwbProgress
          :progress="turnoverProgress"
          color="default"
          size="sm"
          class="progress-bar"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from '@/stores'
import { FwbProgress } from 'flowbite-vue'

const emitter = inject('emitter')
const store = useStore()
const { locale } = useI18n({ useScope: 'global' })

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  showMenu: false,
})
const sumEarning = computed(() => {
  return num(store.userData?.earning)
})

const turnoverProgress = computed(() => {
  return ! store.isUserLoggedIn ? 0 : parseFloat(store.userData.current_turnover) === 0 || parseFloat(store.userData.turnover) === 0 ? 0 : Math.min(100, (store.userData.current_turnover / store.userData.turnover) * 100).toFixed(0)
})

function toggleMenu() {
  store.setShowMenu(!store.showMenu)
}

function getUser() {
  store.getUserData(0)
}

function changeLanguage(lang) {
  locale.value = lang.i18nLang
  emitter.emit('change-language', lang.i18nLang)
  store.updateLanguage(lang.i18nLang)
}
</script>
