<template>
  <main>
    <div v-if="state.page">
      <RouterLink
        to="/"
        class="back-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left inline-block"
        ><path
          stroke="none"
          d="M0 0h24v24H0z"
          fill="none"
        /><path d="M15 6l-6 6l6 6" /></svg>
        Back
      </RouterLink>
      <img
        :src="state.page.image"
        :alt="state.page.title"
        class="w-full h-auto rounded"
      >
      <h1 class="text-xl py-2">
        {{ state.page.title }}
      </h1>
      <!-- eslint-disable -->
      <div v-html="state.page.description" />
      <!-- eslint-enable -->
    </div>
    <div v-else>
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
      >
        <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
        <div class="w-full">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5" />
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5" />
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5" />
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" />
        </div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </main>
</template>

<script setup>
const title = useTitle()
const route = useRoute()

definePage({
  meta: {
    action: 'read',
    subject: 'all',
  },
})

const state = reactive({
  slug: route.params.slug,
  isLoading: false,
  page: null,
})

onMounted(() => {
  getPage()
})

async function getPage() {
  const { data, error } = await useApi(createUrl('api/0/page', {
    query: {
      slug: state.slug,
    },
  })).get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.page = data.value.page
    title.value = state.page.title
  } else if (data.value.message) {
    await swal('Error?', data.value.message, 'error')
  }
}
</script>

<style scoped>
.back-btn svg {
  vertical-align: bottom;
}
</style>
