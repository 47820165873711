import _definePage_default_0 from '/var/www/b247.site/htdocs-frontend/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/b247.site/htdocs-frontend/src/pages/coupon/index.vue?definePage&vue'
import _definePage_default_3 from '/var/www/b247.site/htdocs-frontend/src/pages/deposit/index.vue?definePage&vue'
import _definePage_default_4 from '/var/www/b247.site/htdocs-frontend/src/pages/index3.vue?definePage&vue'
import _definePage_default_5 from '/var/www/b247.site/htdocs-frontend/src/pages/login.vue?definePage&vue'
import _definePage_default_6 from '/var/www/b247.site/htdocs-frontend/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_7 from '/var/www/b247.site/htdocs-frontend/src/pages/page/[slug].vue?definePage&vue'
import _definePage_default_8 from '/var/www/b247.site/htdocs-frontend/src/pages/promotion/index.vue?definePage&vue'
import _definePage_default_9 from '/var/www/b247.site/htdocs-frontend/src/pages/register.vue?definePage&vue'
import _definePage_default_10 from '/var/www/b247.site/htdocs-frontend/src/pages/reward/index.vue?definePage&vue'
import _definePage_default_11 from '/var/www/b247.site/htdocs-frontend/src/pages/user/affiliate.vue?definePage&vue'
import _definePage_default_12 from '/var/www/b247.site/htdocs-frontend/src/pages/user/friend-commission-earning.vue?definePage&vue'
import _definePage_default_13 from '/var/www/b247.site/htdocs-frontend/src/pages/user/friend-lost-earning.vue?definePage&vue'
import _definePage_default_14 from '/var/www/b247.site/htdocs-frontend/src/pages/user/profile.vue?definePage&vue'
import _definePage_default_15 from '/var/www/b247.site/htdocs-frontend/src/pages/user/refer-earning.vue?definePage&vue'
import _definePage_default_16 from '/var/www/b247.site/htdocs-frontend/src/pages/user/self-commission-earning.vue?definePage&vue'
import _definePage_default_17 from '/var/www/b247.site/htdocs-frontend/src/pages/user/transaction.vue?definePage&vue'
import _definePage_default_18 from '/var/www/b247.site/htdocs-frontend/src/pages/withdraw/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/[...error].vue'),
    /* no children */
  },
  {
    path: '/coupon',
    /* internal name: 'coupon' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'coupon',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/coupon/index.vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/deposit',
    /* internal name: 'deposit' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'deposit',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/deposit/index.vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/index3',
    name: 'index3',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/index3.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/page',
    /* internal name: 'page' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':slug',
        name: 'page-slug',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/page/[slug].vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/promotion',
    /* internal name: 'promotion' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'promotion',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/promotion/index.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  {
    path: '/reward',
    /* internal name: 'reward' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'reward',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/reward/index.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/user',
    /* internal name: 'user' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'affiliate',
        name: 'affiliate',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/affiliate.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'friend-commission-earning',
        name: 'friend-commission-earning',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/friend-commission-earning.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
  _mergeRouteRecord(
      {
        path: 'friend-lost-earning',
        name: 'friend-lost-earning',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/friend-lost-earning.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'profile',
        name: 'profile',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/profile.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'refer-earning',
        name: 'refer-earning',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/refer-earning.vue'),
        /* no children */
      },
  _definePage_default_15
  ),
  _mergeRouteRecord(
      {
        path: 'self-commission-earning',
        name: 'self-commission-earning',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/self-commission-earning.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'transaction',
        name: 'transaction',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/user/transaction.vue'),
        /* no children */
        meta: {
          "name": "transaction"
        }
      },
  _definePage_default_17
  )
    ],
  },
  {
    path: '/withdraw',
    /* internal name: 'withdraw' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'withdraw',
        component: () => import('/var/www/b247.site/htdocs-frontend/src/pages/withdraw/index.vue'),
        /* no children */
      },
  _definePage_default_18
  )
    ],
  }
]
