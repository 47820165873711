// 👉 IsEmpty
export const isEmpty = value => {
  if (value === null || value === undefined || value === '')
    return true

  return !!(Array.isArray(value) && value.length === 0)
}

// 👉 IsNullOrUndefined
export const isNullOrUndefined = value => {
  return value === null || value === undefined
}

// 👉 IsEmptyArray
export const isEmptyArray = arr => {
  return Array.isArray(arr) && arr.length === 0
}

// 👉 IsObject
export const isObject = obj => obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj)

// 👉 IsToday
export const isToday = date => {
  const today = new Date()

  return (date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear())
}

export const validateStatus = (rules, value) => {
  for (let i in rules) {
    if (! rules[i](value)) {
      return 'error'
    }
  }

  return value ? 'success' : ''
}

export const validationMessage = (rules, value) => {
  for (let i in rules) {
    const message = rules[i](value)
    if (message !== true) {
      return message
    }
  }

  return ''
}

export const cookieRef = (key, defaultValue) => {
  return useCookie(key, { default: () => defaultValue })
}
